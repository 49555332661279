<template>
  <div id="H5APP">
    <keep-alive>
      <router-view ref="page" :class="{landscape}" />
    </keep-alive>
  </div>
</template>

<script>
import { getToken, setToken } from "@/utils/auth";

export default {
  data() {
    return {
      landscape: false,
      width: 0,
    };
  },
  methods: {
    checkToken() {
      let token = this.$route.query.token;
      if (!token) {
        token = getToken();
      } else {
        setToken(token);
      }
      if (token) {
        this.$router.replace({
          path: "/h5/statistics/main",
        });
      } else if (wx) {
        wx.miniProgram.navigateBack();
      }
    },
    calcFontSize() {
      let clientWidth = this.landscape
        ? document.documentElement.clientHeight
        : document.documentElement.clientWidth;
      let _cfs = window.localStorage.getItem("fontSize");
      if (_cfs) _cfs = parseFloat(_cfs);
      if (isNaN(_cfs) || _cfs < 0.8 || _cfs > 1.2) _cfs = 1;
      let baseFs = Math.floor(100 * _cfs);
      if (clientWidth >= 750) {
        document.documentElement.style.fontSize = baseFs + "px";
      } else {
        document.documentElement.style.fontSize =
          baseFs * (clientWidth / 750) + "px";
      }
    },
    handleResize(e) {
      this.landscape = document.documentElement.clientWidth > document.documentElement.clientHeight;
      this.calcFontSize();
      if (e !== false) {
        this.$nextTick(() => {
          let comp = this.$refs.page;
          if (comp.$children && comp.$children.length) comp = comp.$children[0];
          if (comp.chart) {
            if (comp.chart.resize) {
              comp.chart.resize({
                width: "auto",
                height: "auto",
                silent: true,
              });
            } else if (comp.chart instanceof Object) {
              for (let k in comp.chart) {
                let c = comp.chart[k];
                if (c && c.resize) {
                  c.resize({
                    width: "auto",
                    height: "auto",
                    silent: true,
                  });
                }
              }
            }
          }
        });
      }
    },
  },
  mounted() {
    document.body.classList.add("apph5root");
    this.checkToken();
    window.onresize = this.handleResize;
    this.handleResize(false);
  },
  beforeDestroy() {
    document.body.classList.remove("apph5root");
  },
};
</script>

<style lang="scss">
.apph5root * {
  font-size: 0.24rem !important;
}

#H5APP {
  font-size: 0.28rem;
  min-height: 100%;
  line-height: 1;
  background-color: #f2f2f2;
  user-select: none;

  .h5-page {
    width: 100vw;
    height: 100vh;
  }

  .padding-05 {
    padding: 0.12rem !important;
  }

  .padding-10 {
    padding: 0.24rem !important;
  }

  .fs-small {
    font-size: 0.24rem !important;
  }

  .fs-mini {
    font-size: 0.2rem !important;
  }

  .round-panel {
    border-radius: 0.24rem;
    overflow: hidden;
  }

  .round-panel-top {
    border-radius: 0.24rem 0.24rem 0 0;
    overflow: hidden;
  }

  .round-panel-bottom {
    border-radius: 0 0 0.24rem 0.24rem;
    overflow: hidden;
  }

  .el-input,
  .el-input--small {
    height: auto !important;
    line-height: 1 !important;
    .el-input__inner {
      border: none;
      border-radius: 0;
      padding: 0.24rem;
      font-size: 0.24rem !important;
      height: auto !important;
      line-height: 1 !important;
    }
    .el-input__prefix {
      font-size: 0.24rem !important;
      .el-input__icon {
        line-height: 1;
        margin-top: -0.03rem;
      }
    }
  }

  .el-input-number {
    .el-input-number__decrease,
    .el-input-number__increase {
      border-radius: 0;
      height: auto;
      line-height: 0.8rem;
      border: none;
      background: none;
    }
  }

  .el-date-editor .el-input__inner {
    text-align: center;
  }

  .el-input--prefix .el-input__inner {
    padding-left: 0.64rem;
  }

  .el-radio-group {
    padding: 0.12rem;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-end;

    .el-radio {
      padding: 0.12rem;
      margin: 0;

      .el-radio__label {
        font-size: 0.24rem;
        padding-left: 0.12rem;
      }
    }
  }

  .el-checkbox-group {
    padding: 0.12rem;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-end;

    .el-checkbox {
      padding: 0.12rem;
      margin: 0;

      .el-checkbox__label {
        font-size: 0.28rem;
        padding-left: 0.12rem;
      }
    }
  }

  .ta-r .el-input__inner {
    text-align: right !important;
  }

  .sep-t {
    position: relative;
    &:before {
      z-index: 2;
      position: absolute;
      top: 0;
      left: 0.24rem;
      right: 0;
      height: 1px;
      background: linear-gradient(
        to top,
        transparent 66%,
        rgba(0, 0, 0, 0.2) 66%
      );
      content: "";
    }
  }
  .sep-b {
    position: relative;
    &:before {
      z-index: 2;
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      height: 1px;
      background: linear-gradient(
        to bottom,
        transparent 66%,
        rgba(0, 0, 0, 0.2) 66%
      );
      content: "";
    }
  }

  .el-switch {
    margin: 0 0.24rem;
  }

  .el-button {
    height: 0.76rem;
    font-size: 0.28rem;
    padding: 0.24rem !important;
    line-height: 1 !important;
    border: none;
    border-radius: 0;
  }

  ._filter {
    .arrow {
      background-color: #e2e2e2;
    }
    &.hidden .container {
      display: none;
    }
  }

  .filter-opener {
    display: none;
  }

  .landscape {
    ._filter {
      position: fixed;
      left: 0;
      top: 0;
      bottom: 0;
      width: 7.5rem;
      z-index: 99;
      box-shadow: 0 0 0.14rem rgba(0, 0, 0, 0.3);
      display: flex;
      flex-direction: column;
      overflow: hidden;

      &.hidden {
        transform: translateX(-100%);
        box-shadow: none;

        + .filter-opener {
          display: block;
        }
      }

      .arrow {
        transform: rotate(90deg);
      }

      .items {
        overflow: hidden auto;
      }
    }

    .filter-opener {
      position: fixed;
      left: 0.24rem;
      bottom: 0.24rem;
      width: 0.72rem;
      height: 0.72rem;
      line-height: 0.72rem;
      text-align: center;
      background-color: #409effcc;
      color: white;
      border-radius: 50%;
      z-index: 98;
    }
  }
}
</style>